import React from "react";
import { css } from "@emotion/react";
import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/permanent-marker/index.css"

const Exhibit = ({ caption, children }) => (
  <div css={css`
    padding: 0.75rem;
    box-shadow: 1px 1px 14px 2px rgba(0, 0, 0, 0.2);
    border-radius: 1px;
    border: 1px solid rgba(0,0,0,0.1);
  `}>
  <div
    css={css`
      display: grid;
      align-content: center;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    `}
  >
    <div>
      {children}
    </div>
    <div css={css`
      display: grid;
      align-content: center;
      justify-content: center;
      padding-top: 0.5rem;
    `}>
      <p css={css`
        margin: 0;
        font-family: permanent marker;
      `}>{caption}</p>
    </div>
  </div>
  </div>
);

export default function Gallery() {
  return (
    <div
      css={css`
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(auto-fit, 290px);
        justify-content: center;
        margin: 0 0 0.75rem;
      `}
    >
      <Exhibit caption="Welcome to Utah">
        <StaticImage
          src="../assets/jpg/utah.jpg"
          alt="Utah state border"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Exhibit>
      <Exhibit caption="Main Chute in July 🇺🇸">
        <StaticImage
          src="../assets/jpg/main_chute_skiing.jpg"
          alt="Skiing Alta's Main Chute in July"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Exhibit>
      <Exhibit caption="Sadie">
        <StaticImage
          src="../assets/jpg/biddeford_pool.jpg"
          alt="Sadie dog"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Exhibit>
      <Exhibit caption="Wasatch Crest at Night">
        <StaticImage
          src="../assets/jpg/night_crest.jpg"
          alt="Biking the Wasatch Crest trail at night"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Exhibit>
      <Exhibit caption="Liberty Pass">
        <StaticImage
          src="../assets/jpg/liberty_lake.jpg"
          alt="Liberty Pass - Ruby Mountains, NV"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Exhibit>
      <Exhibit caption="Jacob's Ladder">
        <StaticImage
          src="../assets/jpg/jacobs_ladder.jpg"
          alt="Jacob's Ladder trail - Draper, UT"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Exhibit>
      <Exhibit caption="Brian Head">
        <StaticImage
          src="../assets/jpg/brian_head.jpg"
          alt="Brian Head"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Exhibit>
      <Exhibit caption="Snowbasin with Sadie">
        <StaticImage
          src="../assets/jpg/snowbasin.jpg"
          alt="Skiing Snowbasin with Sadie"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Exhibit>
      <Exhibit caption="Mt Hood">
        <StaticImage
          src="../assets/jpg/hood.jpg"
          alt="Mount Hood at dusk from the columbia river gorge"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Exhibit>
      <Exhibit caption="Lake Sunapee, 2012">
        <StaticImage
          src="../assets/jpg/sunapee.jpg"
          alt="Lake Sunapee Pro/1/2/3 Road Race, 2012"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Exhibit>
      <Exhibit caption="Abe">
        <StaticImage
          src="../assets/jpg/abe.jpg"
          alt="Abe"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Exhibit>
      <Exhibit caption="Tom's Hill Powder">
        <StaticImage
          src="../assets/jpg/toms_hill.jpg"
          alt="Tom's Hill, Wasatch Backcountry"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Exhibit>
      <Exhibit caption="The Olympics at Dusk">
        <StaticImage
          src="../assets/jpg/olympics.jpg"
          alt="The Olympics at night, Washington"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Exhibit>
      <Exhibit caption="Looking Down the Barrel">
        <StaticImage
          src="../assets/jpg/main_chute.jpg"
          alt="Looking down Main Chute at Alta in July"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Exhibit>
      <Exhibit caption="Beartrap Fork">
        <StaticImage
          src="../assets/jpg/beartrap_fork.jpg"
          alt="Beartrap Fork"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Exhibit>
    </div>
  );
}
