import React from 'react';
import { css } from '@emotion/react';

import Gallery from '../components/Gallery';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function About() {
  return (
    <Layout>
      <SEO title="About" description="All about Taylor Reis." />
      <h1
        css={css`
          text-align: center;
        `}
      >
        About
      </h1>
      <p
        css={css`
          margin-top: 0.75rem;
          margin-bottom: 0.75rem;
        `}
      >
        Taylor is a proud Maine native who left New England in search of
        Western mountains. He was taught from a young age that life should be
        a good balance of hard work and time outdoors, turning him into a quiet
        enthusiast of skiing, biking, and problem solving. Taylor can be found
        chasing deep snow and tacky dirt in Utah.
      </p>
      <Gallery />
    </Layout>
  );
}
